<template>
    <div class="box">
      <div class="add">
        <el-button type="primary" @click="added">新增公告</el-button>
        <!-- <el-button type="primary" @click="all">批量删除</el-button> -->
      </div>
      <el-table :data="tableData" style="width: 100%" height="calc(100vh - 180px)"
         border :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
        
       
        <el-table-column prop="noticeName" label="公告名称" align="center">
        </el-table-column>
        <el-table-column prop="noticeContent" label="公告内容" align="center">
        </el-table-column>
        <el-table-column prop="creatTime" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop=" " label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
            <template>
              <el-popconfirm title="这是一段内容确定删除吗？" @onConfirm="handleDelte(scope.row.id)"
                @confirm="handleDelte(scope.row.id)">
                <el-button type="text" slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
            <!-- <el-button type="text" v-if="scope.row.isShelves == 1" @click="upper(scope.row)">下架</el-button>
            <el-button type="text" v-else style="color: red;" @click="upper(scope.row)">上架</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="添加公告" :visible.sync="showDialog" width="800px" height="600px">
        <div class="dia">
          <el-form label-width="120px" label-height="60px">
            <el-row>
              
              <el-col :span="24">
                <el-form-item label="公告名称：" prop="contactName">
                  <el-input v-model="from.noticeName" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="公告内容：" prop="contactName">
                  <el-input v-model="from.noticeContent" placeholder="请输入" clearable></el-input>
                </el-form-item>
              </el-col>
             
            </el-row>
          </el-form>
          <div class="diafour">
            <el-button type="danger" size="medium" @click="showDialog = false">取消</el-button>
            <el-button type="primary" size="medium" @click="save">保存 </el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        tableData: [{
  
        }],
        queryInfo: {
          condition: {
            
          },
          currPage: 1,
          pageCount: 1,
          pageSize: 10
  
        },
        from: {
            noticeName:'',
            noticeContent:''
        //   id: '',
        //   orderSq: '',
        //   areaImg: '',
        //   categoryName: '',
        },
        total: 0,
        showDialog: false,
        fileList: [],
        uploadURL: 'http://121.37.131.119:9003/api/upload/uploadFile',
        disabled: false,
        imageUrl: '',
        xzlist: {}
      };
    },
    mounted() {
      this.getList()
    },
    methods: {
      handleDelte(id) {
        console.log('99');
        var that = this;
        this.$http.post("/notice/delete", {
          id: id
        }).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.$message.success("删除成功");
  
            that.getList()
          }
        });
      },
      //获取数据
      getList() {
        var that = this;
        that.$http.post("/notice/queryList", {
            noticeType:'3'
        }).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            // that.total = response.data.data.count;
            // that.queryInfo.currPage = response.data.data.currPage
            that.tableData = response.data.data;
          }
        });
      },
      added() {
        this.showDialog = true
        this.from = {}
      },
      handleSelectionChange(val) {
        this.xzlist = val
      },
      all() {
        var that = this;
        this.$http.post("/notice/deleteBatch", this.xzlist).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
  
            that.$message.success("成功");
  
            that.getList()
          }
        });
      },
      handleAvatarSuccess(res, file) {
        console.log(file.response.data.url, '88888888');
        this.from.areaImg = file.response.data.url;
      },
      save() {
        var that = this;
        that.from.noticeType = '3'
        this.$http.post("/notice/save", this.from).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            
            that.$message.success("成功");
            that.showDialog = false
            that.getList()
          }
        });
      },
      edit(id) {
        var that = this;
        that.from.id = id
        that.showDialog = true
        that.$http.post("/notice/queryOne", {
          id: id
        }).then(function (response) {
          console.log(response.data.data,'response.data.data.data');
        //   that.from = response.data.data
          that.from.noticeName = response.data.data.noticeName
          that.from.noticeContent = response.data.data.noticeContent
       
  
        });
      },
      upper(row) {
        var that = this;
        this.$http.post("/area/updateStatus", {
          id: row.id,
          isShelves: row.isShelves
        }).then(function (response) {
  
          if (response.data.code == 200) {
  
            that.getList()
          }
        });
  
  
  
      }
  
    }
  };
  </script>
  
  <style lang="less" scoped>
  .box {
    .dia {
      .diafour {
        display: flex;
        justify-content: space-between;
        padding: 20px 230px;
      }
    }
  }
  
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  </style>