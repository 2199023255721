import { render, staticRenderFns } from "./tip.vue?vue&type=template&id=1084de40&scoped=true"
import script from "./tip.vue?vue&type=script&lang=js"
export * from "./tip.vue?vue&type=script&lang=js"
import style0 from "./tip.vue?vue&type=style&index=0&id=1084de40&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1084de40",
  null
  
)

component.options.__file = "tip.vue"
export default component.exports